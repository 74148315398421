body,
body p,
body a {
	font-family: 'Maven Pro', sans-serif;
}

input.form-control {
	border-radius: 0;
}

/*h1 {
	font-weight: 300;
}
*/
/*h3 {
	font-size: 32px;
}
*/
header {
	position: relative;
}

p {
	color: #000;
}

header.dark {
	margin-bottom: 50px;
}

header:not(.dark), .header-media {
	height: 300px;
}

header.dark.background .navbar-fixed-width {
	width: 100%;
	/*height: 45px;*/
	background-color: #fff;
}

header.dark .navbar-default .items {
	border-bottom-color: #000;
	padding-left: 0px!important;
}
@media screen and (max-width: 768px) {
	header .navbar-default .items,
	header.dark .navbar-default .items {
		padding-left: 15px!important;
	}
}

header:not(.dark) .header-media {
	position: absolute;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	/*height: 500px;*/
	/*position: relative;*/
	/*height: calc(560px * 9/16);*/
}

.btn-primary.orange {
	background-color: #F59A00;
	border-color: #f48a00;
	color: #000;
}

.btn-primary.orange:hover {
	color: #333;
}

.bg-grey {
	background-color: #F5F5F5;
}

.img-fullwidth {
	width: 100%;
}

.scroll-down {
	position: absolute;
	bottom: 20px;
	left: 50%;
	color: #000;
	transform: translateX(-50%);
	background-color: #fff;
	border-radius: 50%;
	font-size: 13px;
	padding: 6px 10px 6px 10px;
}

.scroll-down:hover {
	color: #F59A00;
}

#dark-overlay {
	position: fixed;
	display: none;
	height: 100%;
	width: 100%;
	z-index: 1;
	background-color: rgba(0,0,0, 0.40);
}

.loading {
	position: absolute;
/*	border-radius: 50%;*/
	left: 50%;
	top: 50%;
	z-index: -1;
	width: 150px;
	transform: translate(-50%, -50%);
}

/** MENU **/
#navbar {
	padding-left: 0;
	padding-right: 0;
}

.navbar-nav {
	margin: 0;
}

.navbar-default {
	background: transparent;
	margin: 0;
	border-radius: 0;
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom-width: 3px;
	border-bottom-color: #000;
}

.navbar-default .navbar-nav > li.open > a,
.navbar-default .navbar-nav > li:hover > a,
.navbar-default .navbar-nav > li.active > a {
	color: #F59A00 !important;

	background: none !important;
}

.navbar-default .navbar-nav > li:last-child a {
	padding-right: 0px!important
}


.navbar-default .items {
	border-bottom: 3px solid #fff;
	padding-left:0px!important;
}

.navbar-default {
	border-bottom: none;
}

.navbar-default .navbar-nav > li > a {
	color: #fff;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.logo-brand > img {
	position: relative;
	width: 115px;
	top: 5px;
	left: -50px;
}

.navbar-nav {
	padding-top: 7px;
}

.navbar-default {
	z-index: 999;
}

.navbar-default .navbar-nav > li > a:hover {
	color: #F59A00;
}

.mega-dropdown-menu > li > ul > li.dropdown-header > a:hover,
.mega-dropdown-menu > li > ul > li > a:hover {
    color: #F59A00;
}

.mega-dropdown-menu {
    padding: 0;
    width: 70%;
    box-shadow: none;
    -webkit-box-shadow: none;
}

.mega-dropdown-menu > li > ul {
    padding: 0;
    margin: 0;
}

.mega-dropdown-menu > li > ul > li {
    list-style: none;
}

ul.dropdown-menu.mega-dropdown-menu {
    top: 51px;
	border: none;
    background: #fff;
}

header.background ul.dropdown-menu.mega-dropdown-menu {
	top: 71px;
}

li.mega-dropdown > ul.mega-dropdown-menu:not(.search) {
    height: auto;
    overflow: hidden;
    border-radius: 0;
	background: transparent;
}

.menu-content {
	background: #fff;
}

li.mega-dropdown.open > ul.mega-dropdown-menu {
    height: auto;
}

li.mega-dropdown.open > ul.mega-dropdown-menu.small {
	max-width: 100%;
}

.mega-dropdown-menu > li > ul > li > a {
    display: block;
    color: #333333;
    font-weight: 500;
    text-decoration: none;
    font-size: 20px;
    padding: 3px;
}

.mega-dropdown-menu > li > ul > li.dropdown-header > a {
    font-size: 20px;
    color: #333;
    line-height: 30px;
    font-weight: bold;
    text-transform: uppercase;
}

.mega-dropdown-menu .dropdown-header {
    font-size: 50px;
    color: #000;
    line-height: 30px;
    font-weight: 500;
	margin-bottom: 30px;
    padding: 5px 0px 20px 0px;
}

.navbar-default .navbar-nav > li > a {
    text-transform: uppercase;
    text-decoration: none;
    font-size: 18px;
	font-weight: 500;
    color: #000;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #000;
}

header:not(.dark) .navbar-default .navbar-toggle .icon-bar {
	background-color: #fff;
}

.navbar-toggle {
    margin-right: 0;
}

.submenu > ul > li > ul > li > ul >li > a:hover {
    color: #A1C517;
}

ul.navbar-nav > li > a {
    transition: none;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover {
	background: none;

	color: #F59A00;
}

.menu-img {
    padding: 0
}

.menu-content {
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;
}

.mega-dropdown-menu > li > ul.menu-contact {
    margin-top: 40px;
}

.mega-dropdown-menu > li > ul.menu-contact li,
.mega-dropdown-menu > li > ul.menu-contact li a {
    font-size: 16px;
}

/** MOBILE MENU **/
.caret-toggle {
	position: absolute;
	right:10px;
	top:0px;
	z-index: 9999;
	padding:10px;
	margin-right:4px;
}

.submenu {
	text-align: center;
}

.submenu li,
.submenu li a {
	color: #000;
}

.submenu ul {
	list-style-type: none;
	padding-left: 0;
}

li.open a {
	font-size: 14px;
	font-weight: 300;
}

.submenu .dropdown-header {
	font-weight: 500;
	font-size: 22px;
	margin-bottom: 20px;
}

.submenu ul.menu-contact {
	margin-top: 30px;
	margin-bottom: 30px;
}

.submenu ul.menu-contact li,
.submenu ul.menu-contact li a {
	font-size: 11px;
}

.navbar-default .navbar-nav > li > a {
	border-color: #fff;
	padding-left: 16px;
	padding-right: 16px;
}

.menu-img .img-holder {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	width: 100%;
}

/** CONTENT **/
.bg-orange {
	background-color: #F59A00;
}

.bg-orange h2 {
	color: #fff;
}

.btn-orange {
	color: #000;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 25px;
	padding-right: 25px;
	text-transform: uppercase;
	background-color: #F59A00;
	font-weight: 500;
	font-size: 22px;
}

.btn.round {
	border-radius: 50px;
}

.bg-orange {
	padding-top: 75px;
	padding-left: 15px;
	padding-right: 15px;
	padding-bottom: 75px;
}

body.home .content-left {
	padding-left: 50px;
	padding-right: 50px;
	padding-bottom: 30px;
}

/*body.home .content-left h2,
body.home .content-left h3 {
	line-height: 45px;
}
*/
body.home .content-right {
	padding: 100px 50px;
	font-weight: 300;
	color: #000;
}

body.home .content-right p {
	line-height: 38px;
}

body.home .bg-orange h2,
body.home .content-left h2 {
	color: #000;
	font-size: 50px;
	font-weight: 300;
	line-height: 65px;
}

body.home .bg-orange h3,
body.home .content-left h3 {
	color: #fff;
	font-size: 50px;
	font-weight: 300;
	line-height: 65px;
}

.content-image {
	background-image: url('../img/video.jpg');
	background-position: center right;
	background-repeat: no-repeat;
	background-size: contain;
	min-height: 540px;
}

.intro-content {
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	/*max-width: 550px;*/
	/*font-size: 16px;*/
	color: #000;
}

/*.intro-content h3 {
	font-size: 42px;
	font-weight: 300;
	margin-top: 30px;
	margin-bottom: 30px;
}
*/
.intro-content p {
	letter-spacing: 1px;
}

body.home .intro-content h3 {
	margin: 0;
}

body.home .intro-content {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 0;
	color: #fff;
	font-size: 32px;
	transform: translate(-50%, 100%);
}

/** HOME VIDEO **/
section#video .image-video {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	height: 465px;
	position: relative;
}

section#video .image-video .icon-play {
	position: absolute;
	top: 50%;
	left: 50%;
	color: #fff;
	font-size: 80px;
	border-radius: 15px;
	padding: 50px 70px;
	transform: translate(-50%, -50%);
	background-color: rgba(255,255,255, 0.50);
}

section#video .image-video:hover .icon-play {
	background-color: rgba(255,148,0, 0.75);
}

.video-modal .modal-dialog {
	margin: 0;
	height: 100%;
	width: auto;
}

.video-modal .modal-content {
	border-radius: 0;
	background-clip: border-box;
	-webkit-box-shadow: none;
	box-shadow: none;
	border: none;
	min-height: 100%;
	padding: 100px 0;
	text-align: center;
	background: rgba(0,0,0,0.75);
}

.video-modal .modal-content h2 {
	/*margin-bottom: 15px;*/
	/*font-size: 3em;*/
}

.video-modal .modal-content p {
	margin-bottom: 30px;
}

.video-modal .modal-content p.item-intro {
	margin: 20px 0 30px;
	font-family: 'Open Sans', sans-serif;
	font-style: italic;
	font-size: 16px;
}

.video-modal .modal-content ul.list-inline {
	margin-bottom: 30px;
	margin-top: 0;
}

.video-modal .modal-content img {
	margin-bottom: 30px;
}

.video-modal .close-modal {
	position: absolute;
	width: 45px;
	height: 45px;
	top: 15px;
	right: 25px;
	cursor: pointer;
	background-color: transparent;
}

.video-modal .close-modal:hover {
	opacity: 0.75;
}

.video-modal .close-modal .lr {
	height: 45px;
	width: 1px;
	margin-left: 35px;
	background-color: #fff;
	transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	/* IE 9 */
	-webkit-transform: rotate(45deg);
	/* Safari and Chrome */
	z-index: 1051;
}

.video-modal .close-modal .lr .rl {
	height: 45px;
	width: 1px;
	background-color: #fff;
	transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	/* IE 9 */
	-webkit-transform: rotate(90deg);
	/* Safari and Chrome */
	z-index: 1052;
}

.video-modal .modal-backdrop {
	opacity: 0;
	display: none;
}

.home.modal-open .modal {
	padding-right: 0 !important;
}

/** HOME CASES **/
section#cases {
	margin: 50px 0;
}

section#cases .item {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	width: 100%;
	height: 650px;
	position: relative;
	text-align: left;
}

section#cases .img-case {
	width: 100%;
}

section#cases .item .img {
	position: absolute;
	top: 0;
	width: 33.33%;
	height: 440px;
	z-index: 1;
}

section#cases .item .img:nth-child(1) {
	left: 0%;
}

section#cases .item .img:nth-child(2) {
	left: 33.33%;
}

section#cases .item .img:nth-child(3) {
	left: 66.66%;
}

section#cases .item-row:nth-child(odd) .item .case-overlay,
section#cases .item-row:nth-child(even) .item .case-overlay {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	width: 100%;
	height: 100%;
	position: relative;
	transition: background-color 0.75s ease;
}

section#cases .item-row:nth-child(odd) .item .case-overlay {
	background-color: rgba(255,165,0, 0.75);
}

section#cases .item-row:nth-child(even) .item .case-overlay {
	background-color: rgba(0,0,0, 0.20);
}

section#cases .item .btn {
	position: absolute;
	bottom: 30px;
	left: 50%;
	display: none;
	transform: translateX(-50%);
}

section#cases .item .description .inner-hover {
	display: none;
}

section#cases .item > .description {
	position: absolute;
	top: 10%;
	width: 100%;
	height: 90%;
	color: #fff;
	font-size: 22px;
	line-height: 25px;
	letter-spacing: 1px;
	padding: 20px;
}

section#cases .item > .description p {
	color: #fff;
}

section#cases .item {
	margin: 25px 0;
}

section#cases .item .case-logo {
	width: 80px;
	padding-bottom: 20px;
	margin-bottom: 40px;
	border-bottom: 3px solid #fff;
}

/** HOME NEWS **/
section#news .bg-grey {
	padding-bottom: 60px;
}

section#news .item {
	text-align: left;
}

section#news .item h3 a {
	line-height: 30px;
}

section#news .item .news-header {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	width: 100%;
	height: 320px;
}

section#news .item .news-header .news-overlay .icon {
	display: none;
}

section#news .item .news-header:hover .news-overlay {
	width: 90%;
	height: 90%;
	top: 5%;
	left: 5%;
	position: absolute;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;
	-webkit-transition: color 0.30s;
	transition: color 0.30s;
}

section#news .item .news-header {
	position: relative;
}

section#news .item .news-header .news-overlay:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,165,0, 0.75);
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition: 0.30s ease-out;
    transition: 0.30s ease-out;
}

section#news .item .news-header:hover .news-overlay:before {
    -webkit-transform: scale(1);
    transform: scale(1);
}

section#news .item .news-header:hover .news-overlay .icon {
	position: absolute;
	top: 50%;
	left: 50%;
	display: block;
	color: #fff;
	font-size: 24px;
	transform: translate(-50%, -50%);
}

section#news .item .news-header:hover .news-overlay .icon {
    position: relative;
    width: 30px;
    height: 30px;
}

section#news .item .news-header:hover .news-overlay .icon:before,
section#news .item .news-header:hover .news-overlay .icon:after {
	content: "";
	position: absolute;
	background-color: white;
	transition: transform 0.30s ease-out;
}

section#news .item .news-header:hover .news-overlay .icon:before {
	top: 0;
	left: 50%;
	width: 4px;
	height: 100%;
	margin-left: -2px;
}

section#news .item .news-header:hover .news-overlay .icon:after {
	top: 50%;
	left: 0;
	width: 100%;
	height: 4px;
	margin-top: -2px;
}

section#news .item h3 {
	font-size: 22px;
	font-weight: 500;
	line-height: 23px;
	margin-bottom: 3px;
	color: #000;
	max-width: 300px;
}

/*section#news .item p {
	font-size: 22px;
}
*/
section#news .item .date {
	color: #bbb;
	font-size: 22px;
	margin-top: 8px;
	margin-bottom: 8px;
	display: none;
	text-transform: uppercase;
}

section#news .item .description {
	color: #000;
}

section#news .item {
	margin-top: 25px;
	margin-bottom: 25px;
}

section#news h2,
section#partners h2 {
	/*font-size: 28px;*/
	/*font-weight: 300;*/
/*	margin-top: 25px;
	margin-bottom: 0;
*/}

section#partners h2 {
	/*font-size: 16px;*/

	margin-bottom: 0;
}

body.home section#partners .item-row {
	float: none;
    padding: 0;
	margin-left: 20px;
	margin-right: 20px;
    display: inline-block;
}

.news-detail .newsitem {
	padding-left: 75px;
}


/** PRODUCT **/
body:not(.home) section#content {
	margin: 50px 0;
}

section#content ul {
	margin-bottom: 20px;
	padding-left: 18px;
}

.product-image {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	height: 500px;
}

.product-description {
	padding: 10px;
	padding-bottom: 0;
}

.product-description h2 {
	/*font-size: 16px;*/
	margin-bottom: 20px;
}

.product-description p {
	margin-bottom: 0;
}

/** PRODUCT CASES **/
section#product-cases {
	margin: 75px 0;
}

section#product-cases h3 {
	font-weight: 300;
}

section#product-cases .item {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	width: 220px;
	height: 220px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
}

section#product-cases .item:hover {
	outline: #F59A00 solid 50px;
}

section#product-cases .item .case-logo {
	padding-bottom: 20px;
	position: absolute;
	top: 30px;
	left: 30px;
	color: #fff;
	line-height: 25px;
	letter-spacing: 1px;
	margin-bottom: 20px;
}

section#product-cases .item span {
	position: absolute;
	bottom: 25px;
	left: 50%;
	color: #fff;
	width: 100%;
	font-size: 30px;
	transform: translateX(-50%);
}

section#product-cases .item {
	margin-top: 50px;
	margin-bottom: 20px;
}

/** CASE **/
section#case {
	margin: 0 0 50px;
}

section#case .bg-orange {
	 height: 175px;
}

section#case .image-case {
	width: 100%;
}

section#case h3 {
	font-weight: 300;
}

section#case .case-description {
	line-height: 24px;
}

section#case .case-description h5 {
	margin-bottom: 30px;

	font-weight: 500;
}

section#case .case-description strong {
	font-weight: 700;

	font-size: 13px;
}

section#case .case-description {
	padding: 40px 30px 30px 0;

	color: #000;
}

section#case-blocks {
	/*margin: 50px 0;*/

	text-align: right;
}

.column:nth-child(odd) {
	padding-left: 0;
}

.column:nth-child(even) {
	padding-right: 0;
}

section#case-blocks .block-wrapper {
	width: 100%;
}

section#case-blocks .descending-right {
	padding-right:75px;
}

section#case-blocks .left .block-wrapper {
	padding-right:15px;
}

/*section#case-blocks .right img {
	padding-left:15px;
}*/
.grid-filter {
	margin-left:20px;
}
.newsdetail .col-sm-7 img{
	margin-bottom:15px;
}
section#case-blocks .right .block-wrapper {
	padding-left:15px;
}

section#case-blocks .descending-left {
	padding-left:75px;
}

section#case-blocks .text-block {
	line-height: 24px;
	text-align: left;
	float: left;
}

section#case-blocks .block-wrapper .text-block {
	background-color: #F59A00;
	padding: 40px;
	color: #fff;
	width: 45%;
}

section#case-blocks .block-wrapper .text-block,
section#case-blocks .block-wrapper .image-block {
	vertical-align: top;
	height: 375px;
	display: inline-block;
}

section#case-blocks .block-wrapper .image-block {
	position: relative;
	width: 55%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

section#case .case-block .case-overlay {
	background-color: rgba(255,165,0, 0.75);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	width: 100%;
	height: 100%;
	position: relative;
	transition: background-color 0.75s ease;
}

section#case .case-block .case-overlay {
	display: none;
}

section#case .case-block {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	position: relative;
	width: 220px;
	height: 220px;
	top: -50px;
}

section#case .case-column {
	padding: 0;
}

section#case .case-block:hover {
	outline: #F59A00 solid 50px;
}

section#case .case-block .case-logo {
	padding-bottom: 20px;
	position: absolute;
	top: 30px;
	left: 30px;
	color: #fff;
	line-height: 25px;
	letter-spacing: 1px;
	margin-bottom: 20px;
}

section#case .case-block span {
	position: absolute;
	bottom: 20px;
	left: 50%;
	color: #fff;
	width: 100%;
	font-size: 30px;
	text-align: center;
	transform: translateX(-50%);
}

section#case .case-block {
	margin-top: 50px;
	margin-bottom: 20px;
}

/** TEAM **/
section#team {
	margin: 50px 0;
}

section#team .item {
	text-align: left;
}

section#team .item > .image-member {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	width: 100%;
	height: 400px;
}


section#team .item {
	margin-bottom: 50px;

	color: #000;
}

section#team .item.in {
	padding-top: 200px;
}

section#team .item ul {
	list-style-type: none;

	padding-left: 0;
}

section#team .item ul li,
section#team .item ul li a {
	font-size: 13px;
	color: #000;
}

/** PARTNERS **/
#owl-partners .partner-logo {
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}

#owl-partners .partner-logo {
	height: 45px;
}

#owl-partners {
	margin-top: 30px;
	padding-bottom: 20px;
}

.img-center {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

/** FOOTER **/
footer {
	font-size: 22px;
}

footer .footer-top {
	border-top: 1px solid #000;

	padding-top: 50px;
	padding-bottom: 50px;
}

footer .contact {
	line-height: 28px;
}

footer .contact ul {
	padding-left: 0;

	margin-bottom: 0;
}

footer .contact ul li {
	display: inline-block;
}

footer .contact ul li + li:before {
	content: " | ";
	margin: 0 10px;
}

footer .social-media a,
footer .contact a {
	color: #333;
}

footer .logo img {
	width: 110px;
}

footer .social-media ul {
	margin-top: 40px;
	padding-left: 0;
	text-align: right;
}

footer .social-media ul li {
	display: inline-block;
}

footer .social-media a {
	padding: 0 20px;
}

footer .social-media a:last-child {
	padding-right: 0;
}

footer .footer-bottom {
	border-top: 1px solid #000;
	padding-top: 15px;
	padding-bottom: 15px;
	font-size: 14px;
	text-transform: uppercase;
}

footer .footer-top {
	font-size: 20px;
}

footer .footer-bottom a {
	color: #333;
	letter-spacing: 1px;
}

/** BREADCRUMB **/
ol#breadcrumb {
	list-style-type: none;
	text-align: center;
	padding-left: 0;
	margin-top: 50px;
	text-transform: uppercase;
}

ol#breadcrumb li {
	display: inline-block;
}

ol#breadcrumb li,
ol#breadcrumb li a {
	color: #000;
	/*font-size: 15px;*/
}

ol#breadcrumb li + li:before {
	position: relative;
	font-size: 18px;
	top: 1px;
	left: -1px;
	content: "▸";
}

form#contact {
	margin-top: 20px;
}

.videoSection {
	width: 100%;
	height: 500px;
}

/** Google Map **/
#map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.flexible-container {
    position: relative;
    padding-bottom: 60%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.flexible-container iframe,
.flexible-container object,
.flexible-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

a {
	color: #333;
}

/*.intro-content h1 {
	margin-bottom: 25px;
}
*/
.intro-content a,
.product-description a {
	text-decoration: underline;
}

a:hover {
	color: #F59A00;
}

.mega-dropdown-menu.search {
	background-color: #fff;
	z-index: 99999;
	width: 100%;
}

.mega-dropdown-menu.search .menu-content {
	position: relative;
	min-height: 300px;
}

.mega-dropdown-menu.search .menu-content .form-group {
	position: absolute;
	top: 50%;
	width: 100%;
	transform: translateY(-100%);
}

.mega-dropdown-menu.search .menu-content .form-group input {
	border: none;
	box-shadow: none;
	-webkit-box-shadow: none;
	border-bottom: 1px solid #000;
}

body.news-detail span.date {
	display: none;
	text-transform: uppercase;
	font-size: 10px;
	color: #bbb;
	margin-bottom: 30px;
}

body.news-detail h1 {
	margin-top: 30px;
	margin-bottom: 30px;
	font-size: 46px;
	line-height: 65px;
	color: #000;
	max-width: 900px;
}

body.news-detail .content {
	margin-top: 50px;
}

body.news-detail .btn-orange {
	margin-bottom: 30px;
}

section#case-blocks .block-wrapper img {
	margin-top: 30px;
	margin-bottom: 30px;
}

section#case-blocks .block-wrapper .text-block,
section#case-blocks .block-wrapper .image-block {
	margin-top: 30px;
	margin-bottom: 30px;
}

section#case-blocks .block-wrapper.descending-right img {
	margin-top: 30px;
	margin-bottom: 30px;
}

section#case-blocks .block-wrapper.descending-right:not(:first-child) img {
	margin-top: 60px;
}

.images {
	position: relative;
}

.images .img {
	position: absolute;
	left: 0;
	width: 100%;
	height: 25%;
	z-index: 3;
}

.images .img:nth-child(1) {
	top: 0;
}

.images .img:nth-child(2) {
	top: 25%;
}

.images .img:nth-child(3) {
	top: 50%;
}

.images .img:nth-child(4) {
	top: 75%;
}

ol.dots {
	position: absolute;
	z-index: 2;
	top: 50%;
	right: 20px;
	list-style-type: none;
	transform: translateY(-50%);
}

ol.dots li {
	border: 1px solid #fff;
	margin: 15px 0;
	padding: 4px;
	border-radius: 50%;
}

ol.dots li.active {
	background-color: #fff;
}

.product-description {
	line-height: 26px;
}

.product-description b,
.product-description strong {
	font-weight: 500;
}

/*.product-description h2 {
	line-height: 26px;
}
*/
.fullwidth {
    position: relative;
	width: 100%;
}

body.team h3 {
	font-weight: 300;

	font-size: 24px;
}

body.news-detail #content img {
	/*width: 550px;*/

	max-width: 100%;
}

body.news-detail #breadcrumb {
	text-align: left;

	margin-top: 150px;
}

#news-overview {
	margin-top: 30px;
}

/*.column:nth-child(odd) {
	padding-right: 50px;
}*/

.column img:first-child {
	width: 100%;
}

#search input {
	color: #000;
	font-size: 22px;
	padding-left: 3px;
}

/* do not group these rules */
#search input::-webkit-input-placeholder {
    color: #000;
}
#search input:-moz-placeholder {
    /* FF 4-18 */
    color: #000;
}
#search input::-moz-placeholder {
    /* FF 19+ */
    color: #000;
}
#search input:-ms-input-placeholder {
    /* IE 10+ */
    color: #000;
}

#search button.btn {
	position: relative;
	top: 10px;
	left: -35px;
	text-transform: uppercase;
	background-color: transparent;
}

.column:nth-child(odd) img {
	float: right;
}

.quote {
	position: relative;
	color: #000;
	font-size: 80px;
	margin-top: 25px;
	margin-bottom: 75px;
	text-align: center;
}

/*animation element*/
.animation-element {
  opacity: 0;

  position: relative;
}

/*animation element sliding left*/
.animation-element.slide-left {
  opacity: 0;
  -moz-transition: all 500ms linear;
  -webkit-transition: all 500ms linear;
  -o-transition: all 500ms linear;
  transition: all 500ms linear;
  -moz-transform: translate3d(-100px, 0px, 0px);
  -webkit-transform: translate3d(-100px, 0px, 0px);
  -o-transform: translate(-100px, 0px);
  -ms-transform: translate(-100px, 0px);
  transform: translate3d(-100px, 0px, 0px);
}

.animation-element.slide-left.in-view,
.animation-element.slide-left.static {
  opacity: 1;
  -moz-transform: translate3d(0px, 0px, 0px);
  -webkit-transform: translate3d(0px, 0px, 0px);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

h2, strong {
	color: #000;
}

header.background .logo-brand > img {
	top: 3px;
	left: -10px;
	width: 82px;
}

/*body.cases h1 {
	margin-top: 95px;
}
*/
/** PADDING OVERSCHRIJVEN **/
.pl-0 { padding-left: 0 !important; }
.pr-0 { padding-right: 0 !important; }

.no-padding { padding: 0 !important; }

.cases div#filters {
	display: flex;
	justify-content:center;
}

.cases div#filters div.row button.filter-button {
	margin:5px;
	background-color: #F5F5F5;
	border-radius: 0px;
	height:30px;
	min-width:100px;
	font-weight:500;
	color: black;
	text-align: center;
	font-size:14px;
	letter-spacing: 1px;
	border: 0px;
}
.cases div#filters div.row button.filter-button:focus {
	outline: 0;
}
.cases div#filters div.row button.filter-button:hover {
	color: black;
	cursor: pointer;
}

.cases #noItems {
	font-size:20px;
	letter-spacing: 1px;
	display:none;
}

.archive {
	margin-top: 50px;
	text-align: right;
	padding-right: 0px;
	text-transform: uppercase;
	padding-right:0px;
}

.archive a {
	text-decoration: none;
	font-size:13px;
}

@media screen and (max-width: 768px) {
	.archive a {
		font-size: 12px;
	}
}

.newsarchive div#filters {
	display: flex;
	justify-content:center;
}

.newsarchive div#filters div.row button.filter-button {
	margin:5px;
	background-color: #F5F5F5;
	border-radius: 0px;
	height:30px;
	min-width:100px;
	color: black;
	text-align: center;
	font-size:14px;
	font-weight:500;
	letter-spacing: 1px;
	border: 0px;
}
.newsarchive div#filters div.row button.filter-button:focus {
	outline: 0;
}
.newsarchive div#filters div.row button.filter-button:hover {
	color: black;
	cursor: pointer;
}

.newsarchive #noItems {
	font-size:20px;
	letter-spacing: 1px;
	display:none;
}

@media screen and (min-width: 768px) {
	.responsive-languages {
		display: none;
	}
}
.responsive-languages {
	font-size: 14px;
	font-weight: 500;
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0);
	padding-top: 14px;
}
.responsive-languages a {
 text-decoration: none;
}


@media screen and (max-width: 768px) {
	ul#lang {
		display:none;
	}
}

ul#lang {
	list-style-type: none;
	float: left;
	padding-top: 7px;
}

ul#lang a {
	padding-right: 0px!important;
	padding-left: 0px!important;
}

ul#lang li {
    float: left;
		margin-right:5px;
}

ul#lang li:last-child {
		margin-right:0px;
}

ul.light li a {
	font-size: 14px;
	/*color: white;*/
	font-weight: 500;
}
ul#lang li span{
	padding-top:7px;
	font-size: 14px;
	/*color: white;*/
	font-weight: 500;
	display:block;
}

.home ul#lang li span{
	padding-top:7px;
	font-size: 14px;
	color: white;
	font-weight: 500;
	display:block;
}


ul.light li span{
	padding-top:7px;
	font-size: 14px;
	color: white;
	font-weight: 500;
	display:block;
}

ul.dark li a {
	color: black!important;
}

ul.dark li span{
	color: black!important;
}

ul.light li a {
	color: white!important;
}

ul.light li.stripe span {
	color: white!important;
}

li {
  list-style: none;
}

#content li,
body.case .product-description li {
	list-style: inherit;
}

.suga-container {
	/*background: $primary-color;*/
	padding: .2em;
	width: 100%;
	height:50px;

	margin: 5em auto;
	margin-top: 3em;
	/*box-shadow: 3px 3px 0px $secondary-color;
	border: 2px solid $secondary-color;*/
	border-radius: 5px;
}

.suga-slider-wrap {
	overflow: hidden;
	margin: 1em;
}

/*.suga-slider-group {
  &:before,
  &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}*/

.suga-slide {
  float: left;
  position: relative;
  margin-left: 0;
  padding-right: 50px;
}

.suga-slide img {
	height:50px;
	max-width:200px;
}

section#content ul#errors {
	padding-left: 0px;
	color: red;
	font-size: 16px;
}

.product-specifications {
	padding: 10px;
	/*font-size: 15px;*/
	padding-top: 0;
}

.product-specifications .click-specifications{
	cursor: pointer;
	font-weight:500;
}
.product-specifications .click-specifications .fa-angle-up{
	display:none;
}

.product-specifications .product-specifications-text {
	display:none;
	font-size:16px;
}

.newsdetail {
	width: 100%;
}

.newsdetail .image {
	float: right;
	width: 58.33%;
	margin-left: 50px;
	margin-bottom: 20px;
}

ul#errors {
	list-style: none;
}

/*body.case section#case .case-block:hover {
	outline: 0;
}*/

.loading-large {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	z-index: -1;
}

.menu-content span.title {
	font-weight: 700;
	font-size: 20px;
	color: #333333;
}

.menu-content span.title {
	display: block;

	margin-top: 20px;
}

body.case .icon-play:hover i {
	color: #F59A00;
}

.icon-play i {
	position: absolute;
	top: 50%;
	left: 50%;
	color: #fff;
	font-size: 72px;
	transform: translate(-50%, -50%);
	text-shadow: 2px 2px 16px #272634;
}

.grid-filter {
	margin-left: 0;
}

.grid-filter .element-item {
	position: static !important;
	left: auto !important;
	top: auto !important;
}

body.products .bg-orange {
	padding: 0;
}

body.products #main-image {
	position: relative;

	z-index: 2;
}

body.products .bg-left {
	z-index: 1;
}

/** CALCULATOR **/
.calculator-wrapper {
	background-color: #fff;
}

.calculator.classic {
	margin-top: -15px;
}

.calculator.digital {
	margin-top: 50px;
}

.calculator .calculator-row {
	background-color: rgb(230,230,230);
	font-size: 16px;
	color: #000;
	padding: 3px 10px;
	margin-top: 5px;
	margin-bottom: 5px;
	-moz-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
    -webkit-box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
    box-shadow: 0 2px 2px rgba(0,0,0, 0.4);
}

.calculator .calculator-row.orange {
	background-color: #F59A00;
	text-transform: uppercase;
	font-weight: 500;
	color: #fff;
}

.calculator .calculator-row strong {
	font-weight: 500;
}

.calculator .options {
	margin: 5px -5px 5px -5px;
}

.calculator .options .calculator-row {
	width: calc(20% - 15px);
	padding: 10px;
	margin: 0 5px;
	text-align: center;
	display: inline-block;
}

.calculator .options .calculator-row.active,
.calculator .options .calculator-row.active:hover,
.calculator .options .calculator-row.active:focus {
	background-color: rgb(185,185,185);
}

.calculator .options .calculator-row:hover {
	cursor: pointer;

	background-color: #e0e0e0;
}

.calculator .calculator-row.options-value {
	margin-top: 35px;
}

.calculator .calculator-row .size {
	color: #989898;
}

.calculator .calculator-row .size, .calculator .calculator-row .quantity {
	font-size: 30px;

	display: block;
}

.calculator .calculator-row hr {
	margin: 3px auto;
	width: 40px;
	border-color: #989898;
}

.calculator .calculator-row .unit {
	line-height: 1;
	font-size: 12px;
	display: block;
	text-transform: uppercase;
}

.calculator .calculator-row.value {
	font-size: 15px;
}

.calculator .calculator-row.value.orange {
	background-color: rgb(250, 204, 127);
	font-weight: 500;
	color: #000;
}

.calculator .calculator-row.value.orange-dark {
	background-color: #F8B33E;
	font-weight: 500;
	color: #000;
}

.calculator .calculator-row.value .price {
	float: right;
}

.calculator .calculator-row #quantity {
	background-color: rgb(250, 204, 127);
	width: 50px;
	height: 30px;
	float: right;
	border: 1px solid #D0B78B;
}

.swal-button.swal-button--confirm {
	background-color: #F59A00;
}

.swal-button.swal-button--confirm:hover,
.swal-button.swal-button--confirm:focus {
	background-color: #ea9102;
}

#form-calculator {
	margin-top: 10px;
}

#form-calculator,
#form-calculator p {
	font-size: 16px;
}

#form-calculator label {
	margin-top: 20px;

	display: block;
}

#form-calculator input[type="email"],
#form-calculator button[type="submit"] {
	display: inline;
}

#form-calculator input[type="email"] {
	width: calc(100% - 140px);
	padding: 0 10px;
	border: none;
	height: 40px;
	background: #eee;
}

#form-calculator button[type="submit"] {
	font-weight: 500;
	width: 140px;
	color: #000;
	height: 40px;
	border-radius: 0;
	margin-top: -3px;
	margin-left: -5px;
	letter-spacing: 1px;
	background-color: #F59A00;
}

#form-calculator button[type="submit"]:hover,
#form-calculator button[type="submit"]:focus {
	background-color: #ea9102;
}

#form-calculator #loading {
	display: none;
	width: 50px;
	margin-top: 20px;
}

@media (max-width: 992px) {
	.calculator .calculator-row {
		font-size: 13px;
	}

	.calculator .calculator-row #quantity {
		height: 25px;
	}

	.calculator .calculator-row .size, .calculator .calculator-row .quantity {
		font-size: 20px;
	}

	.calculator .calculator-row hr {
		width: 35px;
	}
}

@media (max-width: 768px) {
	.calculator.classic {
		margin-top: 10px;
	}

	.calculator .calculator-row, .calculator .calculator-row.value {
		font-size: 11px;
	}

	.calculator .calculator-row .size, .calculator .calculator-row .quantity {
		font-size: 14px;
	}

	.calculator .calculator-row hr {
		width: 20px;
	}

	.calculator .calculator-row .unit {
		font-size: 9px;
	}

	.calculator .calculator-row {
		padding: 3px 5px;
	}

	.calculator .calculator-row #quantity {
		float: none;
	}

	.calculator .options .calculator-row {
		width: calc(20% - 10px);

		margin: 0 3px;
	}
}

@media (min-width: 1600px) {
	body.calculator section#content .col-md-7 {
		width: 62.5% !important;
	}

	body.calculator section#content .col-md-5 {
		width: 37.5% !important;
	}
}
