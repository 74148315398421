/** VASTE POSITIES EN BREEDTES **/
@media (min-width: 1800px) {
	section#cases .item-row,
	section#news .item-row,
	section#team .item-row,
	section#product-cases .item-row {
		float: none;
		padding: 0;
		margin: 0 20px;
		display: inline-block;
	}

	/** BOF: CASES **/
	body:not(.home) section#news,
	section#cases,
	section#team {
		max-width: 2000px;
		margin-left: auto;
		margin-right: auto;
	}

	section#cases .item-wrapper,
	section#cases .item-row,
	section#cases .item {
		width: 520px;
	}

	section#cases .item, section#cases .item .img {
		height: 635px;
	}

	section#product-cases .item .case-logo {
		width: 110px;
	}
	/** EOF: CASES **/

	/** BOF: News **/
	section#news .item-row,
	section#news .item .news-header {
		width: 520px;

		vertical-align: top;
	}

	section#news .item .news-header {
		height: 322px;
	}
	/** EOF: News **/

	/** BOF: TEAMS **/
	section#team .item-row,
	section#team .item,
	section#team .item > .image-member {
		width: 520px;

		vertical-align: top;
	}

	section#team .item > .image-member {
		height: 631px;
	}
	/** EOF: TEAMS **/

	/** BOF: Product cases **/
	section#product-cases .item-row {
		margin: 0 40px;
	}

	section#product-cases .item-row,
	section#product-cases .item,
	section#case .case-block {
		width: 380px;
	}

	section#product-cases .item,
	section#case .case-block {
		height: 380px;
	}
	/** EOF: Product cases **/

	/** BOF: Home video **/
	section#video .image-video {
		height: 800px;
	}
	/** EOF: Home video **/

	/** BOF: FONTS **/
/*	body.home .bg-orange h2, body.home .content-left h2,
	body.home .bg-orange h3, body.home .content-left h3 {
		font-size: 50px;

		line-height: 70px;
	}
*/
/*	section#news h2 {
		font-size: 26px;
	}
*/
/*	section#partners h2 {
		font-size: 16px;
	}
*/
/*	p,
	section#news .item h3,
	.product-description,
	body.home .content-right p {
		line-height: 30px;
	}
*/
/*	body.home .content-right p {
		line-height: 34px;
	}
*/
/*	h1,
	body.news-detail h1 {
		font-size: 52px;
		line-height: 75px;
	}
*/
	body.news-detail h1 {
		max-width: 920px;
	}

/*	h3 {
		font-size: 40px;
	}
*/
/*	.product-description h2 {
		font-size: 21px;
	}
*/
/*	p,
	.btn-orange,
	footer .footer-top,
	section#news .item h3,
	section#news .item p,
	.intro-content p,
	.product-description,
	section#team .item ul li,
	section#team .item ul li a,
	section#case-blocks .text-block,
	section#cases .item > .description,
	section#case .case-description strong {
		font-size: 19px;
	}
*/
/*	body.home .content-right p {
		font-size: 22px;

		line-height: 35px;
	}

	ol#breadcrumb li,
	ol#breadcrumb li a {
		font-size: 18px;
	}

	section#news .item .date,
	body.news-detail span.date,
	.navbar-default .navbar-nav > li > a {
		font-size: 17px;
	}

	footer .footer-bottom a {
		font-size: 15px;
	}

	.inner-description p,
	.inner-hover {
		font-size: 28px;
		line-height: 38px;
	}
*/	/** EOF: FONTS **/

	/*.intro-content {
		max-width: 700px;
	}*/

	section#partners .item-row {
		width: 520px;
	}
}

@media (max-width: 1800px) {
	section#cases .item-row,
	section#news .item-row,
	section#team .item-row,
	section#product-cases .item-row {
		float: none;
		padding: 0;
		margin: 0 20px;
		display: inline-block;
	}

	/** BOF: CASES **/
	section#cases .item-wrapper,
	section#cases .item-row,
	section#cases .item {
		width: 420px;
	}

	section#cases .item, section#cases .item .img {
		height: 513px;
	}

	section#product-cases .item .case-logo {
		width: 110px;
	}
	/** EOF: CASES **/

	/** BOF: News **/
	section#news .item-row,
	section#news .item .news-header {
		width: 420px;

		vertical-align: top;
	}

	section#news .item .news-header {
		height: 260px;
	}
	/** EOF: News **/

	/** BOF: TEAMS **/
	section#team .item-row,
	section#team .item,
	section#team .item > .image-member {
		width: 420px;

		vertical-align: top;
	}

	section#team .item > .image-member {
		height: 510px;
	}
	/** EOF: TEAMS **/

	/** BOF: Product cases **/
	section#product-cases .item-row {
		margin: 0 40px;
	}

	section#product-cases .item-row,
	section#product-cases .item,
	section#case .case-block {
		width: 380px;
	}

	section#product-cases .item,
	section#case .case-block {
		height: 380px;
	}
	/** EOF: Product cases **/

	/** BOF: Home video **/
	section#video .image-video {
		height: 750px;
	}
	/** EOF: Home video **/

	/** BOF: FONTS **/
/*	body.home .bg-orange h2, body.home .content-left h2,
	body.home .bg-orange h3, body.home .content-left h3 {
		font-size: 48px;

		line-height: 65px;
	}
*/
/*	section#news h2 {
		font-size: 24px;
	}
*/
/*	section#partners h2 {
		font-size: 16px;
	}
*/
/*	p,
	section#news .item h3,
	.product-description,
	body.home .content-right p {
		line-height: 28px;
	}
*/
/*	body.home .content-right p {
		line-height: 32px;
	}

*//*	h1,
	body.news-detail h1 {
		font-size: 50px;
		line-height: 60px;
	}
*/
/*	h3 {
		font-size: 36px;
	}
*/
/*	.product-description h2 {
		font-size: 20px;
	}
*/
/*	p,
	.btn-orange,
	footer .footer-top,
	section#news .item h3,
	section#news .item p,
	.intro-content p,
	.product-description,
	section#team .item ul li,
	section#team .item ul li a,
	section#case-blocks .text-block,
	section#cases .item > .description,
	section#case .case-description strong {
		font-size: 18px;
	}
*/
/*	body.home .content-right p {
		font-size: 19px;

		line-height: 32px;
	}

	ol#breadcrumb li,
	ol#breadcrumb li a {
		font-size: 17px;
	}

	section#news .item .date,
	body.news-detail span.date,
	.navbar-default .navbar-nav > li > a {
		font-size: 16px;
	}

	footer .footer-bottom a {
		font-size: 14px;
	}

	section#product-cases .item span,
	section#case .case-block span {
		font-size: 29px;
	}

	.inner-description p,
	.inner-hover {
		font-size: 24px;
		line-height: 34px;
	}
*/	/** EOF: FONTS **/

	.intro-content {
		/*max-width: 700px;*/
	}

	section#partners .item-row {
		width: 420px;
	}
}

@media (max-width: 1500px) {
	/** BOF: CASES **/
	section#cases .item-wrapper,
	section#cases .item-row,
	section#cases .item {
		width: 340px;
	}

	section#cases .item, section#cases .item .img {
		height: 415px;
	}

	section#product-cases .item .case-logo {
		width: 90px;
	}
	/** EOF: CASES **/

	/** BOF: News **/
	section#news .item-row,
	section#news .item .news-header {
		width: 340px;
	}

	section#news .item .news-header {
		height: 210px;
	}
	/** EOF: News **/

	/** BOF: TEAMS **/
	section#team .item-row,
	section#team .item,
	section#team .item > .image-member {
		width: 345px;
	}

	section#team .item > .image-member {
		height: 419px;
	}
	/** EOF: TEAMS **/

	/** BOF: Product cases **/
	section#product-cases .item-row {
		margin: 0 40px;
	}

	section#product-cases .item-row,
	section#product-cases .item,
	section#case .case-block {
		width: 300px;
	}

	section#product-cases .item,
	section#case .case-block {
		height: 300px;
	}
	/** EOF: Product cases **/

	/** BOF: Home video **/
	section#video .image-video {
		height: 750px;
	}
	/** EOF: Home video **/

	/** BOF: FONTS **/
/*	body.home .bg-orange h2, body.home .content-left h2,
	body.home .bg-orange h3, body.home .content-left h3 {
		font-size: 42px;

		line-height: 55px;
	}
*/
/*	section#news h2 {
		font-size: 22px;
	}
*/
/*	section#partners h2 {
		font-size: 16px;
	}
*/
/*	p,
	section#news .item h3,
	.product-description,
	body.home .content-right p {
		line-height: 26px;
	}
*/
/*	h1,
	body.news-detail h1 {
		line-height: 65px;
	}
*/
/*	h1, body.news-detail h1 {
		font-size: 50px;

		line-height: 60px;
	}
*/
/*	h3 {
		font-size: 32px;
	}
*/
/*	.product-description h2 {
		font-size: 18px;
	}
*/
/*	p,
	.btn-orange,
	footer .footer-top,
	section#news .item h3,
	section#news .item p,
	.intro-content p,
	.product-description,
	section#team .item ul li,
	section#team .item ul li a,
	section#case-blocks .text-block,
	section#cases .item > .description,
	section#case .case-description strong {
		font-size: 16px;
	}
*/
/*	body.home .content-right p {
		font-size: 18px;

		line-height: 30px;
	}
*/
/*	ol#breadcrumb li,
	ol#breadcrumb li a {
		font-size: 15px;
	}
*/
/*	section#news .item .date,
	body.news-detail span.date,
	.navbar-default .navbar-nav > li > a {
		font-size: 14px;
	}
*/
/*	footer .footer-bottom a {
		font-size: 12px;
	}

	.inner-description p,
	.inner-hover {
		font-size: 20px;
		line-height: 28px;
	}
*/	/** EOF: FONTS **/

	.intro-content {
		/*max-width: 700px;*/
	}

	section#partners .item-row {
		width: 340px;
	}

/*	section#product-cases .item span,
	section#case .case-block span {
		font-size: 27px;
	}
*/}

@media (max-width: 1275px) {
	/** BOF: CASES **/
	section#cases .item-wrapper,
	section#cases .item-row,
	section#cases .item {
		width: 400px;
	}

	section#cases .item, section#cases .item .img {
		height: 489px;
	}
	/** EOF: CASES **/

	/** BOF: News **/
	section#news .item-row,
	section#news .item .news-header {
		width: 250px;
	}

	section#news .item .news-header {
		height: 155px;
	}
	/** EOF: News **/

	/** BOF: TEAMS **/
	section#team .item-row,
	section#team .item,
	section#team .item > .image-member {
		width: 250px;
	}

	section#team .item > .image-member {
		height: 303px;
	}
	/** EOF: TEAMS **/

	/** BOF: Product cases **/
	section#product-cases .item-row {
		margin: 0 40px;
	}

	section#product-cases .item-row,
	section#product-cases .item,
	section#case .case-block{
		width: 210px;
	}

	section#product-cases .item,
	section#case .case-block {
		height: 210px;
	}
	/** EOF: Product cases **/

	/** BOF: Home video **/
	section#video .image-video {
		height: 560px;
	}

	section#product-cases .item .case-logo {
		width: 70px;
	}
	/** EOF: Home video **/

	/** BOF: FONTS **/
/*	body.home .bg-orange h2, body.home .content-left h2,
	body.home .bg-orange h3, body.home .content-left h3 {
		font-size: 40px;

		line-height: 50px;
	}
*/
/*	section#news h2 {
		font-size: 21px;
	}
*/
/*	section#partners h2 {
		font-size: 16px;
	}
*/
/*	p,
	section#news .item h3,
	.product-description {
		line-height: 25px;
	}
*/
/*	body.home .content-right p {
		line-height: 28px
	}

	h1 {
		font-size: 43px;
		line-height: 62px;
	}
*/
/*	h1, body.news-detail h1 {
		font-size: 46px;
	}
*/
/*	h3 {
		font-size: 30px;

		line-height: 68px;
	}
*/
/*	.product-description h2 {
		font-size: 17px;
	}
*/
/*	p,
	.btn-orange,
	footer .footer-top,
	section#news .item h3,
	section#news .item p,
	.intro-content p,
	.product-description,
	body.home .content-right,
	section#team .item ul li,
	section#team .item ul li a,
	section#case-blocks .text-block,
	section#cases .item > .description,
	section#case .case-description strong {
		font-size: 15px;
	}
*/
	ol#breadcrumb li,
	ol#breadcrumb li a {
		font-size: 14px;
	}

	section#news .item .date,
	body.news-detail span.date,
	.navbar-default .navbar-nav > li > a {
		font-size: 13px;
	}

	footer .footer-bottom a {
		font-size: 11px;
	}

	section#product-cases .item span,
	section#case .case-block span {
		font-size: 25px;
	}

	.inner-description p,
	.inner-hover {
		font-size: 23px;
		line-height: 32px;
	}
	/** EOF: FONTS **/

	section#partners .item-row {
		width: 250px;
	}
}

@media (max-width: 992px) {
	/** BOF: CASES **/
	section#cases .item-wrapper,
	section#cases .item-row,
	section#cases .item {
		width: 285px;
	}

	section#cases .item, section#cases .item .img {
		height: 348px;
	}

	section#product-cases .item .case-logo {
		width: 60px;
	}
	/** EOF: CASES **/

	/** BOF: News **/
	section#news .item-row,
	section#news .item .news-header {
		width: 285px;
	}

	section#news .item .news-header {
		height: 176px;
	}
	/** EOF: News **/

	/** BOF: TEAMS **/
	section#team .item-row,
	section#team .item,
	section#team .item > .image-member {
		width: 285px;
	}

	section#team .item > .image-member {
		height: 346px;
	}
	/** EOF: TEAMS **/

	/** BOF: Product cases **/
	section#product-cases .item-row {
		margin: 0 40px;
	}

	section#product-cases .item-row,
	section#product-cases .item,
	section#case .case-block {
		width: 250px;
	}

	section#product-cases .item,
	section#case .case-block {
		height: 250px;
	}
	/** EOF: Product cases **/

	/** BOF: Home video **/
	section#video .image-video {
		height: 475px;
	}
	/** EOF: Home video **/

	/** BOF: FONTS **/
/*	body.home .bg-orange h2, body.home .content-left h2,
	body.home .bg-orange h3, body.home .content-left h3 {
		font-size: 38px;

		line-height: 46px;
	}
*/
/*	section#news h2 {
		font-size: 20px;
	}
*/
/*	section#partners h2 {
		font-size: 16px;
	}
*/
/*	p,
	section#news .item h3,
	.product-description,
	body.home .content-right p {
		line-height: 24px;
	}
*/
/*	body.home .content-right p {
		font-size: 16px;

		line-height: 26px;
	}

	h1 {
		font-size: 40px;
		line-height: 60px;
	}

	h1, body.news-detail h1 {
		font-size: 39px;

		max-width: 760px;
	}
*/
/*	h3 {
		font-size: 28px;
	}

	.product-description h2 {
		font-size: 16px;
	}
*/
/*	p,
	.btn-orange,
	footer .footer-top,
	section#news .item h3,
	section#news .item p,
	.intro-content p,
	.product-description,
	body.home .content-right,
	section#team .item ul li,
	section#team .item ul li a,
	section#case-blocks .text-block,
	section#cases .item > .description,
	section#case .case-description strong {
		font-size: 14px;
	}
*/
	ol#breadcrumb li,
	ol#breadcrumb li a {
		font-size: 13px;
	}

	section#news .item .date,
	body.news-detail span.date,
	.navbar-default .navbar-nav > li > a {
		font-size: 12px;
	}

	footer .footer-bottom a {
		font-size: 10px;
	}

	.inner-description p,
	.inner-hover {
		font-size: 16px;
		line-height: 24px;
	}
	/** EOF: FONTS **/

	section#partners .item-row {
		width: 285px;
	}
}

@media (max-width: 768px) {
	/** BOF: CASES **/
	section#cases .item-wrapper,
	section#cases .item-row,
	section#cases .item {
		width: 400px;
	}

	section#cases .item, section#cases .item .img {
		height: 488px;
	}

	section#product-cases .item .case-logo {
		width: 110px;
	}
	/** EOF: CASES **/

	/** BOF: News **/
	section#news .item-row,
	section#news .item .news-header {
		width: 400px;
	}

	section#news .item .news-header {
		height: 248px;
	}

	section#news .item {
		margin-top: 30px;
		margin-bottom: 30px;
	}

	section#news .item-row:first-child .item {
		margin-top: 0;
	}

	/** EOF: News **/

	/** BOF: TEAMS **/
	section#team .item-row,
	section#team .item,
	section#team .item > .image-member {
		width: 400px;
	}

	section#team .item > .image-member {
		height: 485px;
	}
	/** EOF: TEAMS **/

	/** BOF: Product cases **/
	section#product-cases .item-row {
		margin: 0;
	}

	section#product-cases .item-row,
	section#product-cases .item {
		width: 380px;
	}

	section#product-cases .item {
		height: 380px;
	}
	/** EOF: Product cases **/

	/** BOF: Home video **/
	section#video .image-video {
		height: 450px;
	}
	/** EOF: Home video **/

	/** BOF: FONTS **/
/*	body.home .bg-orange h2, body.home .content-left h2,
	body.home .bg-orange h3, body.home .content-left h3 {
		font-size: 36px;

		line-height: 43px;
	}
*/
/*	section#news h2 {
		font-size: 19px;
	}
*/
/*	section#partners h2 {
		font-size: 16px;
	}
*/
/*	p,
	section#news .item h3,
	.product-description,
	body.home .content-right p {
		line-height: 23px;
	}
*/
/*	h1 {
		font-size: 38px;
		line-height: 55px;
	}
*/
/*	h1, body.news-detail h1 {
		font-size: 36px;
		line-height: 52px;
		max-width: 760px;
	}
*/
/*	h3 {
		font-size: 26px;
	}

	.product-description h2 {
		font-size: 15px;
	}
*/
/*	p,
	.btn-orange,
	footer .footer-top,
	section#news .item h3,
	section#news .item p,
	.intro-content p,
	.product-description,
	body.home .content-right,
	section#team .item ul li,
	section#team .item ul li a,
	section#case-blocks .text-block,
	section#cases .item > .description,
	section#case .case-description strong {
		font-size: 13px;
	}
*/
	ol#breadcrumb li,
	ol#breadcrumb li a {
		font-size: 12px;
	}

	section#news .item .date,
	body.news-detail span.date,
	.navbar-default .navbar-nav > li > a {
		font-size: 11px;
	}

	footer .footer-bottom a {
		font-size: 9px;
	}

	.inner-description p,
	.inner-hover {
		font-size: 23px;
		line-height: 32px;
	}

	body.news-detail #breadcrumb {
		margin-top: 50px;
	}
	/** EOF: FONTS **/

	section#partners .item-row {
		width: 400px;
	}
}

@media (max-width: 450px) {
	/** BOF: CASES **/
	section#cases .item-wrapper,
	section#cases .item-row,
	section#cases .item {
		width: 260px;
	}

	section#cases .item, section#cases .item .img {
		height: 317px;
	}

	section#case-blocks .block-wrapper .text-block {
		padding: 20px;
	}

	section#product-cases .item .case-logo {
		width: 60px;
	}
	/** EOF: CASES **/

	/** BOF: News **/
	section#news .item-row,
	section#news .item .news-header {
		width: 260px;
	}

	section#news .item .news-header {
		height: 160px;
	}
	/** EOF: News **/

	/** BOF: TEAMS **/
	section#team .item-row,
	section#team .item,
	section#team .item > .image-member {
		width: 260px;
	}

	section#team .item > .image-member {
		height: 315px;
	}
	/** EOF: TEAMS **/

	/** BOF: Product cases **/
	section#product-cases .item-row,
	section#product-cases .item {
		width: 230px;
	}

	section#product-cases .item {
		height: 230px;
	}
	/** EOF: Product cases **/

	/** BOF: Home video **/
	section#video .image-video {
		height: 350px;
	}
	/** EOF: Home video **/

	.inner-description p,
	.inner-hover {
		font-size: 16px;
		line-height: 24px;
	}

	section#partners .item-row {
		width: 260px;
	}
}

/** ALGEMENE MEDIA QUERIES (FONTS, MARGINS ETC) **/
@media (max-width: 1700px) {
	.content-image {
		min-height: 380px;
	}
}

@media (max-width: 1280px) {
	.quote {
		font-size: 80px;
	}

	.logo-brand > img {
		width: 90px;
		top: 12px;
		left: -15px;
	}

	.logo-brand > img {
		top: 12px;
	}
}

@media (max-width: 1250px) {
	@media (min-width: 992px) {
		section#cases .item .case-logo {
			width: 60px;
		}

		section#cases .item .case-logo {
			margin-bottom: 20px;
		}
	}
}

@media (max-width: 1200px) {
	body.home .content-left {
		padding-left: 0;
		padding-right: 0;
	}

	body.home .content-right {
		padding: 30px;
	}
}

@media (max-width: 992px) {
	footer .social-media a {
		padding: 0 10px;
	}

	body.home .content-right {
		padding: 30px;
	}

	.btn-orange {
		font-size: 14px;
	}

	section#team .item.in {
		padding-top: 0;
	}

	section#case-blocks .text-block {
		padding: 10px;
	}

	.logo-brand > img,
	.logo-brand-mobile > img {
		width: 60px;
		top: 23px;
	}

	body.home .intro-content {
		transform: translate(-50%, -50%);
	}

	.mega-dropdown-menu {
		width: 100%;
	}

	@media (min-width: 768px) {
		section#case-blocks .block-wrapper .text-block,
		section#case-blocks .block-wrapper .image-block {
			height: 250px
		}

		.content-image {
			min-width: 220px;
			min-height: 220px;
		}

		section#product-cases .item .case-logo {
			width: 50px;
			top: 10px;
			left: 10px;
		}

		section#product-cases .item span,
		section#case .case-block span {
			font-size: 16px;
		}

		section#news .row:last-child .item-row:last-child,
		section#product-cases .row:last-child .item-row:last-child,
		section#cases.odd .row:last-child .item-row:last-child {
			margin-right: 345px;
		}

		section#news h2,
		section#partners h2 {
			margin-bottom: 0;
		}
	}
}

@media (max-width: 768px) {
	.element-item {
		position: static !important;
	}

	header.light {
		margin-top: 50px;
	}

	body.news-detail .container-fluid.newsitem {
		padding-left: 15px;
	}

	ul.dropdown-menu > li.pull-right {
		float: none !important;
	}

	section#news .item {
		margin-top: 25px;
		margin-bottom: 25px;
	}

	.navbar-fixed-bottom .navbar-collapse, .navbar-fixed-top .navbar-collapse {
		max-height: 540px;
	}

	.menu-content {
		padding-top: 10px;
		padding-bottom: 40px;
	}

	section#case .case-description {
		padding-left: 10px;
	}

	section#case-blocks .column {
		padding: 0;
	}

	.navbar-default {
		background-color: #fff;
	}

	.navbar-default .items {
		border-bottom: 1px solid #f4f4f4 !important;
	}

	header:not(.dark) .navbar-default .navbar-toggle .icon-bar,
	header.dark .navbar-default .navbar-toggle .icon-bar {
		background-color: #000;
	}

	footer img.img-center {
		margin: 0;
	}

	footer .social-media ul {
		padding: 0;

		margin-top: 10px;
		text-align: left;
	}

	footer .social-media ul li:first-child a {
		padding-left: 0;
	}

	body.home .content-right {
		padding: 20px;
	}

	section#video .image-video {
		height: 300px;
	}

	.menu-img {
		display: none;
	}

	.navbar-nav > .open > a {
		visibility: hidden;
	}

	li.dropdown-hover > a {
		border-right: 1px solid #eee;
	}

	.logo-brand > img, .logo-brand-mobile > img {
		position: absolute;
		top: 1px;
		left: 15px;
		z-index: 1000;
	}

	.block-wrapper .text-block,
	.block-wrapper .image-block {
		width: 100%;
		left: 0;
	}

	.block-wrapper .text-block {
		height: 150px;
	}

	section#case-blocks .img-fullwidth {
		margin-top: 50px;
	}

	section#case .case-block {
		left: 0;
		top: -25px;
		margin: 50px 0;
		margin-left: auto;
		margin-right: auto;
	}

	section#case .case-block:hover {
		outline-width: 20px;
	}

	section#product-cases .item:hover {
		outline-width: 20px;
	}

	.navbar-default .navbar-nav > li > a {
        margin-right: 60px;
    }

    ul.dropdown-menu > li {
        border-bottom: 1px solid #eee;

        position: relative;
    }

    .navbar-nav .open .dropdown-menu > li > a {
        padding: 10px;
    }

    ul.dropdown-menu > li:hover > a {
        background: rgba(0, 0, 0, 0.03);
    }

    .navbar-nav .open .dropdown-menu > li > a {
        line-height: 30px;
        height: 40px;
    }

	.navbar-default .navbar-nav > li > a {
        background-color: #fff;
        color: #000;
    }

    li.open a {
        text-transform: uppercase;
        font-weight: bold;
        color: #000;
    }

    .navbar-nav {
        margin-bottom: 0;
    }

	li.open a {
        color: #999;
        font-size: 21px;
        font-weight: 600;
    }

    .navbar-default .navbar-nav > li > a {
        font-size: 14px;
    }

    .intro-header h2 {
        font-size: 25px;
        padding: 0 30px;
    }

    .navbar-nav .open .dropdown-menu > li > a {
        padding-top: 17px;
    }

    .navbar-nav > li > a,
    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        height: 40px;
        font-weight: bold;
        color: #333;
    }

	.container-fluid > .navbar-collapse,
    .container-fluid > .navbar-header,
    .container > .navbar-collapse,
    .container > .navbar-header {
        margin-right: -15px;
        margin-left: -15px;
        padding-right: 15px;
    }

    .navbar-toggle {
        left: 15px;
		border: none;
    }

	.navbar-default .navbar-toggle:focus,
	.navbar-default .navbar-toggle:hover {
		background-color: transparent;
	}

	.navbar-default .navbar-nav .open .dropdown-menu > li.active-sub > a {
        background-color: #fff;
    }

    #navbar li.open > a {
        color: #A1C517;
    }

	.navbar-header {
        float: none;
    }

    .navbar-left,
    .navbar-right {
        float: none;
    }

    .navbar-toggle {
        display: block;
    }

    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    }

    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }

    .navbar-collapse.collapse {
        display: none;
    }

    .navbar-nav {
        float: none;
    }

    .navbar-nav > li {
        float: none;
        background: #fff;
        border-bottom: 1px solid #f4f4f4;
    }

    .navbar-nav > li > a {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .collapse.in {
        display: block;
    }

    .navbar {
        height: 50px;
    }

    .navbar-nav .open .dropdown-menu .dropdown-header {
        padding: 0px;

		margin-bottom: 30px;
    }

    .navbar-nav .open .dropdown-menu > li.dropdown-header > a {
        font-weight: bold;
        font-size: 15px;
        text-transform: uppercase;
        text-decoration: none;
        padding: 5px 0px 5px 0px;
    }

    .navbar-nav .open .dropdown-menu > li > a {
        padding: 5px 0px 5px 10px;
        text-decoration: none;
    }

    .navbar-nav .open .dropdown-menu {
        padding-left: 0px;
    }

    .dropdown-menu {
        padding: 0px;
    }

	.navbar-nav {
		padding-top: 0;
		margin-top: 0;
	}

	.navbar .container-fluid {
		padding-left: 15px;
		padding-right: 15px;
	}

	body.home .intro-content h3 {
		font-size: 24px;
	}

	body.home .content-left {
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media (max-width: 500px) {
	.quote {
		font-size: 80px;
	}
}

@media (max-width: 460px) {
	.footer-bottom .pull-right {
		float: none !important;

		text-align: left !important;
	}

	section#cases .item .case-logo {
		width: 50px;
	}
}

@media (max-width: 375px) {
	footer .social-media ul li a {
		padding: 0 3px;
	}
}

@media (max-width: 340px) {
	section#team .item > .image-member {
		height: 300px;
	}

	.quote {
		font-size: 60px;
	}
}

@media (min-width: 768px) {
	.container-fluid {
		padding-left: 60px;
		padding-right: 60px;
	}

	body.news-detail .container-fluid.newsitem-content {
		padding-left: 75px;
	}

	.navbar-default .items {
		padding-right: 0;
		padding-bottom: 7px;
	}

	.bg-left {
		background: #F59A00;
		left: 0;
		top: 0;
		bottom: 0;
		width: 50%;
		position: absolute;
	}

	body.products .bg-left {
		width: 60%;
	}

	.bg-right {
		right: 0;
		top: 0;
		bottom: 0;
		width: 50%;
		background: #FFF;
		position: absolute;
	}

	body.products .bg-right {
		width: 40%;
	}

	.caret-toggle {
		display: none;
	}

	.navbar-nav > li.mega-dropdown {
		position: static;
	}

	.navbar-nav > li > a {
        padding-top: 7px;
        padding-bottom: 7px;
    }

	.navbar .container-fluid {
		padding-left: 0;
		padding-right: 0;
	}

	/** HEADER **/
	header:not(.dark) .navbar-default .navbar-nav > li > a,
	header:not(.dark) .navbar-default .navbar-nav > li.open > a {
		color: #fff;
	}

	header .navbar-default .row {
		margin-left: 60px;
		margin-right: 60px;
	}

	.row.row-margin {
		margin-left: 30px;
	}

	header.dark.background .navbar-default .items #navbar {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	header .navbar-nav.navbar-right li:last-child a {
		padding-right: 0;
		padding-right: 0; /* 15px */
	}

	.mega-dropdown-menu > li > ul:first-child {
		min-height: 255px;
	}

	body.home section#content .col-sm-7 {
		width: 60.5%;
	}

	body.home section#content .col-sm-5 {
		width: 39.5%;
	}

	body.contact section#content .col-sm-7,
	body.products section#content .col-sm-7,
	body.case section#case .col-sm-7,
	body.page section#content .col-sm-7 {
		width: 62.5%;
	}

	body.contact section#content .col-sm-5,
	body.products section#content .col-sm-5,
	body.case section#case .col-sm-5,
	body.page section#content .col-sm-5 {
		width: 37.5%;
	}
}

@media (min-width: 992px) {
	/*section#news .item-row:nth-of-type(1n) .item {
		padding-right: 10px;
	}

	section#news .item-row:nth-of-type(3n+2) .item {
		padding: 0 10px;
	}

	section#news .item-row:nth-of-type(3n) .item {
		padding-left: 10px;
	}*/

	/*body.home section#content .col-sm-7,
	body.contact section#content .col-sm-7,
	body.products section#content .col-sm-7,
	body.case section#case .col-sm-7 {
		width: 70.5%;
	}

	body.home section#content .col-sm-5,
	body.contact section#content .col-sm-5,
	body.products section#content .col-sm-5,
	body.case section#case .col-sm-5 {
		width: 29.5%;
	}*/
}

@media (min-width: 1200px) {
	.quote {
		font-size: 100px;
	}

	section#cases .container,
	section#news .container {
		width: 1200px;
	}
}

@media (min-width: 1300px) {
	.scroll-down {
		bottom: 90px;
	}
}

@media (min-width: 1500px) {
	.quote {
		font-size: 120px;
	}

	body.news-detail span.date {
		margin-bottom: 50px;
	}

	section#team .item {
		margin-bottom: 150px;
	}

	.scroll-down {
		bottom: 150px;
	}
}

/** NAVBAR MEDIA QUERIES **/
@media all and (min-width: 768px) {
	.navbar-fixed-width {
		/*width: 650px;*/
		margin-left: auto;
		margin-right:auto;
	}
}

@media all and (min-width: 992px) {
	.navbar-fixed-width {
		/*width: 940px;*/
		margin-left: auto;
		margin-right:auto;
	}
}

@media all and (min-width: 1200px) {
	.navbar-fixed-width {
		/*width: 1140px;*/
		margin-left: auto;
		margin-right:auto;
	}
}
